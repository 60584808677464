<div class="main-card-with-tab mb-3 card">
    <div class="card-header">
        <h2>Create user</h2>
    </div>
    <div class="card-body">
        <div class="container">
            <div class="row mt-4">
                <div class="col-12">
                    <div class="position-relative form-group">
                        <label for="username">Email</label>
                        <input class="form-control" id="username" name="username" type="text" [(ngModel)]="user.username">
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="userpsw">Password</label>
                        <input class="form-control" id="userpsw" name="userpsw" type="password" [(ngModel)]="user.clearPassword" [class.fail-validation]="confirmPassword!=user.clearPassword">
                    </div>
                </div>
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="userpswconfirm">Confirm password</label>
                        <input class="form-control" id="userpswconfirm" name="userpswconfirm" type="password"
                               [(ngModel)]="confirmPassword" [class.fail-validation]="confirmPassword!=user.clearPassword">
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="usrlang">Language</label>

                        <ng-select [(ngModel)]="user.lang" id="usrlang" >
                            <ng-option *ngFor="let item of langs" [value]="item.value">{{item.label}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="usrprofile">Profile</label>

                        <ng-select [(ngModel)]="profileSelected" id="usrprofile" >
                            <ng-option *ngFor="let item of profiles" [value]="item">{{item.name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button type="button" class="btn btn-light mx-3" (click)="modal.dismissAll('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()">SAVE</button>
    </div>
</div>


