import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RestService} from "../../../service/rest.service";
import {GuiService} from "../../../service/gui.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {faPlus, faStar} from '@fortawesome/free-solid-svg-icons';
import JSONFormatter from 'json-formatter-js';

@Component({
    selector: 'app-booking-list',
    templateUrl: './booking-list.component.html'
})
export class BookingListComponent implements OnInit {
    heading = 'Booking';
    subheading = 'List of trace integrations event';
    icon = 'pe-7s-note2 icon-gradient bg-happy-itmeo';

    faStar = faStar;
    faPlus = faPlus;

    totalItems: number = 0;
    page: number = 1;
    size: number = 25;

    structures: Array<any> = [];
    filteredStructure: any = null;
    filter: any = {};

    bookings: any = [];
    selectedItem: any = {}

    constructor(private router: Router, private rest: RestService, private gui: GuiService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.gui.componentView = 'booking';

        this.bookings = [];
        this.searchStructure();
    }

    loadItems() {
        this.gui.loaded = false;
        this.bookings = [];
        this.rest.getService('booking', '/' + this.filteredStructure + '?page=' + (this.page - 1) + '&size=' + this.size).subscribe((ris) => {
                console.debug("RIS", ris);
                this.bookings = ris.items;
                this.totalItems = ris.totalItems;
            },
            (err) => {
                // nothing
            },
            () => {
                this.gui.loaded = true;
            });
    }

    pageChange(ev: any) {
        console.debug("EVENT", ev);
        this.page = (typeof ev == "number") ? ev : 0;
        this.loadItems();
    }

    viewItem(content: any, item: any) {
        this.selectedItem = new JSONFormatter(item);

        this.selectedItem.openAtDepth(3);

        this.modalService.open(content, {size: 'xl'});
    }

    searchStructure() {
        this.rest.getService('structure', '?page=0&size=20').subscribe((ris) => {
            console.debug("RIS", ris);
            this.structures = ris.items;
        });
    }

}
