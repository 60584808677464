import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestService} from '../../../service/rest.service';

@Component({
  selector: 'app-structure-detail',
  templateUrl: './structure-detail.component.html'
})
export class StructureDetailComponent implements OnInit {
  @Input() item:any;
  @Output() reloadList = new EventEmitter();
  heading = 'Structure Detail';
  subheading = 'Area to view and configuration the structure integrations';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  structure: any = {
    "address": "",
    "cap": "",
    "city": "",
    "email": "",
    "name": "",
    "phoneNumber": ""
  };

  constructor(private router: Router, public modal: NgbModal, private rest: RestService) { }

  ngOnInit(): void {
    this.structure = this.item;
  }

  save() {
    let param: any = {
      "id": this.structure.id,
      "address": this.structure.address,
      "cap": this.structure.cap,
      "city": this.structure.city,
      "email": this.structure.email,
      "enabled": this.structure.enabled,
      "name": this.structure.name,
      "phoneNumber": this.structure.phoneNumber
    };

    this.rest.putService('structure','',param).subscribe((ris)=>{
      this.reloadList.emit();
      this.modal.dismissAll();
    });
  }

}
