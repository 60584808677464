import {Component, ElementRef, Input, OnChanges, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import JSONFormatter from 'json-formatter-js';
import {element} from "protractor";

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html'
})
export class BookingDetailComponent implements OnChanges {
  @Input() data: any;

  constructor(private element: ElementRef, public modal: NgbModal) {}

  ngOnChanges() {
    let tmp = document.getElementById("container-id");

    const formatter = new JSONFormatter(this.data);
    tmp.appendChild(formatter.render());
  }
}
