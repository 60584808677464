import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RestService} from "../../../service/rest.service";
import {GuiService} from "../../../service/gui.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {faStar, faPlus} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-trace',
    templateUrl: './trace.component.html'
})
export class TraceComponent implements OnInit {
    heading = 'Trace';
    subheading = 'List of trace integrations event';
    icon = 'pe-7s-attention icon-gradient bg-happy-itmeo';

    faStar = faStar;
    faPlus = faPlus;

    totalItems: number = 0;
    page: number = 1;
    size: number = 10;

    logs: any = [];

    constructor(private router: Router, private rest: RestService, private gui: GuiService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.gui.componentView = 'logs';

        this.logs = [];
        this.loadItems();
    }

    loadItems() {
        this.rest.getService('status', '?page='+(this.page-1)+'&size='+this.size).subscribe((ris) => {
                console.debug("RIS", ris);
                this.logs = ris.items;
                this.totalItems = ris.totalItems;
            },
            (err) => {
                // nothing
            },
            () => {
                this.gui.loaded = true;
            });
    }

    createNew(content: any) {
        this.modalService.open(content, {size: 'xl'});
    }

    pageChange(ev: any) {
        console.debug("EVENT", ev);
        this.page = (typeof ev == "number") ? ev : 0;
        this.loadItems();
    }

}
