import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'BRiG - Collega Il Futuro Con Il Tuo BRiG Digitale';
}
