/* tslint:disable */
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import 'rxjs';
import {environment} from 'src/environments/environment';
import {GuiService} from "./gui.service";

export interface AuthData {
    token: string
}

@Injectable({
    providedIn: 'root'
})
export class RestService {
    tkn: any = '';
    lang = '';
    baseURL: any = environment.baseURL;
    baseOPT = {};
    public tknSubj = new BehaviorSubject<null | AuthData>(null)
    token$ = this.tknSubj.asObservable()
    msg_times: number = 5000;

    constructor(public router: Router, private httpClient: HttpClient, public gui: GuiService) {

    }

    public setTkn(tkn: any) {
        this.tkn = tkn;
        sessionStorage.setItem('tkn', tkn);
    }

    public getTkn() {
        this.tkn = sessionStorage.getItem('tkn');
        return sessionStorage.getItem('tkn');
    }

    public getService(base: any, ctx: any, opt?: any): Observable<any> {
        !opt ? opt = this.baseOPT : null;

        if (sessionStorage.getItem('tkn') || sessionStorage.getItem('tkn') != null) {
            opt = {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('tkn'),
                    'Content-Type': 'application/json'
                }
            };
        }

        return this.httpClient.get(this.baseURL[base] + '' + ctx, opt).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);

                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }


    public doLogout() {
        this.setTkn(null);
        this.tknSubj.next(null);
        this.tkn = null;
        sessionStorage.removeItem('tkn');
        this.router.navigate(['']);
    }

    public postService(base: any, ctx: any, json: any, opt?: any): Observable<any> {

        !opt ? opt = this.baseOPT : null;

        if (sessionStorage.getItem('tkn') || sessionStorage.getItem('tkn') != null) {
            opt = {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('tkn')
                }
            };
        }

        return this.httpClient.post(this.baseURL[base] + '' + ctx, json, opt).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);

                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }

    public putService(base: any, ctx: any, json: any, opt?: any): Observable<any> {

        !opt ? opt = this.baseOPT : null;

        if (sessionStorage.getItem('tkn') || sessionStorage.getItem('tkn') != null) {
            opt = {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('tkn')
                }
            };
        }

        return this.httpClient.put(this.baseURL[base] + '' + ctx, json, opt).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);
                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }

    public patchService(base: any, ctx: any, json: any, opt?: any): Observable<any> {

        !opt ? opt = this.baseOPT : null;

        if (sessionStorage.getItem('tkn') || sessionStorage.getItem('tkn') != null) {
            opt = {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('tkn')
                }
            };
        }

        return this.httpClient.patch(this.baseURL[base] + '' + ctx, json, opt).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);
                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }

    public deleteService(base: any, ctx: any, json: any, opt?: any): Observable<any> {

        !opt ? opt = this.baseOPT : null;

        return this.httpClient.delete(this.baseURL[base] + '' + ctx, {responseType: 'text'}).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);
                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }

    public doLogin(ctx: any, json: any) {
        let opt = {};
        return this.httpClient.post(this.baseURL.login, json, opt).pipe(
            catchError((err) => {

                this.gui.auth_result = 400;
                setTimeout(()=> {
                    this.gui.auth_result = null;
                },this.msg_times);
                console.debug('error caught in service',err);
                console.error(err.message);
                //Handle the error here

                return throwError(err);    //Rethrow it back to component
            })
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            this.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }

    private log(message: string) {
        console.debug(message);
    }

}
