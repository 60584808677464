import {Component, Input} from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {GuiService} from '../../../service/gui.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent {

  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() subheading;
  @Input() icon;

  constructor(private route: Router, private gui: GuiService) {
  }

  createNew() {
    this.route.navigate([this.gui.componentView+'-new']);
  }
}
