import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

// BOOTSTRAP COMPONENTS

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';

// Dashboards

import {AnalyticsComponent} from './pages/Dashboards/analytics/analytics.component';

// Pages

import {ForgotPasswordBoxedComponent} from './pages/Auth/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './pages/Auth/login-boxed/login-boxed.component';
import {RegisterBoxedComponent} from './pages/Auth/register-boxed/register-boxed.component';

import {StructureListComponent} from './pages/Structures/structure-list/structure-list.component';
import {UsersListComponent} from './pages/Users/user-list/users-list.component';
import { StructureNewComponent } from './pages/Structures/structure-new/structure-new.component';
import {IntegrationListComponent} from './pages/Integrations/integration-list/integration-list.component';
import {IntegrationNewComponent} from './pages/Integrations/integration-new/integration-new.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { HotelCinqueStelleComponent } from './pages/Integrations/Provider/hotel-cinque-stelle/hotel-cinque-stelle.component';
import { SlopeComponent } from './pages/Integrations/Provider/slope/slope.component';
import { ZakComponent } from './pages/Integrations/Provider/zak/zak.component';
import { IntegrationEditComponent } from './pages/Integrations/integration-edit/integration-edit.component';
import { UserDetailComponent } from './pages/Users/user-detail/user-detail.component';
import { TraceComponent } from './pages/Monitoring/trace/trace.component';
import { BookingListComponent } from './pages/Booking/booking-list/booking-list.component';
import { BookingDetailComponent } from './pages/Booking/booking-detail/booking-detail.component';
import {StructureDetailComponent} from "./pages/Structures/structure-detail/structure-detail.component";
import { UserNewComponent } from './pages/Users/user-new/user-new.component';
import {Zakv2Component} from "./pages/Integrations/Provider/zak_v2/zakv2.component";
import { CloudbedsComponent } from './pages/Integrations/Provider/cloudbeds/cloudbeds.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT
    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR
    SidebarComponent,
    LogoComponent,

    // FOOTER
    FooterComponent,

    // Dashboards
    AnalyticsComponent,

    // User Pages
    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Structures
    StructureListComponent,

    // Integrations
    IntegrationListComponent,
    IntegrationNewComponent,

    // Users
    UsersListComponent,
    StructureNewComponent,
    StructureDetailComponent,
    HotelCinqueStelleComponent,
    SlopeComponent,
    ZakComponent,
    Zakv2Component,
    IntegrationEditComponent,
    UserDetailComponent,
    TraceComponent,
    BookingListComponent,
    BookingDetailComponent,
    UserNewComponent,
    CloudbedsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts
    ChartsModule,

    // NG Select
    NgSelectModule,
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
