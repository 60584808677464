import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestService} from '../../../service/rest.service';

@Component({
  selector: 'app-structure-new',
  templateUrl: './structure-new.component.html'
})
export class StructureNewComponent implements OnInit {
  @Output() reloadList = new EventEmitter();
  heading = 'Structure Detail';
  subheading = 'Area to view and configuration the structure integrations';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  structure: any = {
    "address": "",
    "cap": "",
    "city": "",
    "email": "",
    "name": "",
    "phoneNumber": ""
  };

  constructor(private router: Router, public modal: NgbModal, private rest: RestService) { }

  ngOnInit(): void {
  }

  save() {
    this.rest.postService('structure','',this.structure).subscribe((ris)=>{

      this.reloadList.emit();
      this.modal.dismissAll();
    });
  }

}
