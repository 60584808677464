import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RestService} from '../../../../service/rest.service';

@Component({
  selector: 'app-hotel-cinque-stelle',
  templateUrl: './hotel-cinque-stelle.component.html',
})
export class HotelCinqueStelleComponent implements OnInit {
  @Input('strconfig') strconfig: any;
  @Output() newItemEvent = new EventEmitter<string>();
  JSON: any = JSON;

  sources: Array<any> = [];
  sourceItems: string = "";
  sourceOrigin: Array<any> = [];

  statusM: Array<any> = [];
  statusItems: string = "";
  statusedited: boolean = false;

  saleschannels: Array<any> = [];
  saleschannelItems: string = "";
  saleschannelOrigin: Array<any> = [];


  integrationProperties: any = [{
      key: "CINQUE_STELLE_CLIENT_TOKEN",
      label: "CLIENT TOKEN",
      val: ""
    },{
      key: "CINQUE_STELLE_HOTEL_CODE",
      label: "HOTEL CODE",
      val: ""
    }];

  webhookProperties: any = {
    "structureId": null,
    "webHookProperties": {
      "endpoint": {
        "apiKey": "",
        "authHeaderType": "BEARER",
        "baseUrl": "https://pmsx-dot-revolution-plus-nuova-arch.oa.r.appspot.com/rev_push/v2/booking",
        "contentType": "application/json",
        "contentTypeInBody": false,
        "httpMethod": "POST",
        "method": "",
        "paginated": false,
        "pathParamNumber": 0,
        "returnObj": null
      },
      "name": ""
    }
  };
  webhookPropEdited: boolean = false;

  constructor(private rest: RestService) { }

  ngOnInit(): void {
    this.initValue();
  }

  initValue() {
    for (let k in this.integrationProperties) {
      let item = this.integrationProperties[k];
      this.strconfig.customProperties[item.key] ? this.integrationProperties[k].val = this.strconfig.customProperties[item.key] : null;
    }

    // SOURCE
    this.sources = [];
    for(let k in this.strconfig.sourceMapping) {
      this.sourceOrigin.push({
        key: k,
        val: this.strconfig.sourceMapping[k]
      });
      this.sources.push({
        key: k,
        val: this.strconfig.sourceMapping[k]
      });
    }

    // SALES CHANNEL
    this.saleschannels = [];
    for(let k in this.strconfig.channelMapping) {
      this.saleschannelOrigin.push({
        key: k,
        val: this.strconfig.channelMapping[k]
      });
      this.saleschannels.push({
        key: k,
        val: this.strconfig.channelMapping[k]
      });
    }

    // STATUS
    this.statusM = [];
    for(let k in this.strconfig.statusMapping) {
      this.statusM.push({
        key: k,
        val: this.strconfig.statusMapping[k]
      });
    }
  }

  updateIntegrationProperty() {
    this.newItemEvent.emit(this.integrationProperties);
  }


  // SOURCE MAPPING
  changeSource() {
  }
  removeSource(idx: number) {
    this.sources.splice(idx,1);
  }
  saveSource() {
    let param = {
      "sourceMapping": [],
      "structureId": this.strconfig.structureId
    }

    for(let k in this.sources) {
      param.sourceMapping.push({
        "externalSource": this.sources[k].key,
        "internalSource": this.sources[k].val
      });
    }

    console.debug("SOURCE SAVE",param);
    this.rest.postService('strconfig','/source-mapping',param).subscribe( (ris) => {
      console.debug("SOURCE SAVE", ris);
    });

  }


  // SALES CHANNEL MAPPING
  changeSC() {
  }
  removeSC(idx: number) {
    this.saleschannels.splice(idx,1);
  }
  saveSC() {
    let param = {
      "channelMapping": [],
      "structureId": this.strconfig.structureId
    }

    for(let k in this.saleschannels) {
      param.channelMapping.push({
        "externalChannel": this.saleschannels[k].key,
        "internalChannel": this.saleschannels[k].val
      });
    }

    console.debug("SALES CHANNEL SAVE",param);
    this.rest.postService('strconfig','/channel-mapping',param).subscribe( (ris) => {
      console.debug("SOURCE SAVE", ris);
    });
  }


  // STATUS MAPPING
  changeStatus() {
    this.statusedited = true;
  }

  saveStatus() {}

  saveWebhook() {}
}
