<div class="main-card-with-tab mb-3 card">
    <div class="card-header">
        <h2>Create structure configuration</h2>
    </div>
    <div class="card-body form-style">
        <div class="container">
            <div class="row mt-4">
                <div class="col-12">
                    <div class="position-relative form-group">
                        <label for="strid">Structure</label>
                        <ng-select [(ngModel)]="selectedStr" id="strid" >
                            <ng-option *ngFor="let item of structures" [value]="item">{{item.name}}</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-4">
                    <div class="position-relative form-group">
                        <label for="pmsSystem">PMS</label>
                        <ng-select [(ngModel)]="selectedPMS" id="pmsSystem" (change)="changePMSSelect()">
                            <ng-option *ngFor="let item of pmslist" [value]="item.name">{{item.label}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="position-relative form-group">
                        <label for="timeSlot">Trigger times</label>
                        <ng-select [(ngModel)]="selectedSlot" id="timeSlot" [multiple]="true">
                            <ng-option *ngFor="let item of timeSlot" [value]="item">{{item}}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="position-relative form-group">
                        <label for="checkinDate">Date to start</label>
                        <input class="form-control" id="checkinDate" name="checkinDate" type="date" [(ngModel)]="checkinDate">
                    </div>
                </div>
            </div>

            <!-- PROVIDER CONFIG -->
            <div *ngIf="selectedPMS">
                <app-hotel-cinque-stelle [strconfig]="strconfig" *ngIf="selectedPMS=='CINQUESTELLE'" (newItemEvent)="setIntegrationProperties($event)"></app-hotel-cinque-stelle>
                <app-slope [strconfig]="strconfig" *ngIf="selectedPMS=='SLOPE'" (newItemEvent)="setIntegrationProperties($event)"></app-slope>
                <app-zak [strconfig]="strconfig" *ngIf="selectedPMS=='ZAK'" (newItemEvent)="setIntegrationProperties($event)"></app-zak>
                <app-zak-v2 [strconfig]="strconfig" *ngIf="selectedPMS=='ZAK_V2'" (newItemEvent)="setIntegrationProperties($event)"></app-zak-v2>
                <app-cloudbeds [strconfig]="strconfig" *ngIf="selectedPMS=='CLOUDBEDS'" (newItemEvent)="setIntegrationProperties($event)" ></app-cloudbeds>
            </div>

        </div>
    </div>
    <div class="card-footer text-end">
        <button type="button" class="btn btn-light mx-3" (click)="modal.dismissAll('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()">SAVE</button>
    </div>
</div>

