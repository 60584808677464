/* tslint:disable */
import {Component, OnInit} from '@angular/core';
import {faStar, faPlus} from '@fortawesome/free-solid-svg-icons';
import {RestService} from '../../../service/rest.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GuiService} from "../../../service/gui.service";

@Component({
    selector: 'app-user-list',
    templateUrl: './integration-list.component.html'
})
export class IntegrationListComponent implements OnInit {
    heading = 'Integrations';
    subheading = 'List of structure integrations';
    icon = 'pe-7s-plugin icon-gradient bg-happy-itmeo';

    selectedItem: any = null;
    loadEdit: boolean = false;

    faStar = faStar;
    faPlus = faPlus;

    totalItems: number = 0;
    page: number = 0;

    structures: any = [];

    generateKeyEnabled: boolean = false;
    generateKeyAuth: any = {
        "username": "",
        "password": ""
    };
    authkey: string = null;

    constructor(private rest: RestService, private modalService: NgbModal, public gui: GuiService) {
    }

    ngOnInit() {
        this.structures = [];
        this.loadItems();
    }

    loadItems() {
        this.rest.getService('strconfig', '').subscribe((ris) => {
                console.debug("RIS", ris);
                this.structures = ris.items;
                this.totalItems = ris.totalItems;
            },
            (err) => {
                // nothing
            },
            () => {
                this.gui.loaded = true;
            });
    }

    createNew(content: any) {
        this.loadEdit = false;
        this.modalService.open(content, {size: 'xl'});
    }

    editItem(content: any, item: any) {
        this.selectedItem = item;
        this.loadEdit = true;
        this.modalService.open(content, {size: 'xl'});
    }

    preGenerateKey(content) {
        this.generateKeyEnabled = true;
        this.modalService.open(content, {size: 'xl'});
    }

    generateKey() {
        this.rest.postService("base","/jwt/api_key",this.generateKeyAuth).subscribe((ris)=>{
            console.debug("KEY",ris);
            this.authkey = ris.token ? ris.token : null;
        })

    }

    reloadList() {
        this.loadItems();
    }

}
