<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <i class="pe-7s-user icon-gradient bg-premium-dark vsm-icon"></i>
                </div>
              </span>
            </button>
            <div ngbDropdownMenu>
              <button class="dropdown-item">Profile</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item text-danger" (click)="logout()">LOGOUT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

