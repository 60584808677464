import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestService} from '../../../service/rest.service';

import * as moment from 'moment';

@Component({
  selector: 'app-integration-new',
  templateUrl: './integration-new.component.html'
})
export class IntegrationNewComponent implements OnInit {
  @Output() reloadList = new EventEmitter();
  heading = 'Integrations';
  subheading = 'List of structure integrations';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  pmslist: any = [{
    id: 0,
    name: "CINQUESTELLE",
    label: "Hotel 5 Stelle"
  },{
    id: 1,
    name: "SLOPE",
    label: "Slope"
  },{
    id: 2,
    name: "ZAK",
    label: "Zak"
  },{
    id: 3,
    name: "ZAK_V2",
    label: "Zak (v2)"
  },{
    id: 3,
    name: "CLOUDBEDS",
    label: "CloudBeds"
  }];
  selectedPMS: any = null;

  timeSlot: Array<string> = [
    "00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00",
    "12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00",
  ];
  selectedSlot: any = null;
  checkinDate: any = null;

  structures: any = [];
  selectedStr: any = null;
  strconfig: any = {
    "name": "",
    "structureId": "",
    "pmsSystem": "",
    "timeSlot": "",
    "checkinDate": "",
    "customProperties": []
  };

  constructor(private router: Router, public modal: NgbModal, private rest: RestService) { }

  ngOnInit(): void {
    this.getStructures();
  }

  getStructures() {
    this.rest.getService('structure','?page=0&size=300').subscribe((ris)=> {
      this.structures = ris.items;
    });
  }
  changePMSSelect() {
    console.debug("PMS",this.selectedPMS);
  }
  goBack() {
    this.router.navigate(['structures']);
  }

  save() {

    this.selectedSlot && this.selectedSlot.length > 0 ? this.strconfig.timeSlot = this.selectedSlot.join("::") : null;
    this.strconfig.checkinDate = this.checkinDate ? moment(this.checkinDate).format('DD/MM/YYYY') : null;
    this.strconfig.structureId = this.selectedStr.id;
    this.strconfig.name = this.selectedStr.name;
    this.strconfig.pmsSystem = this.selectedPMS;

    console.debug("INTEGRATION CFG " + JSON.stringify(this.strconfig));

    this.rest.postService('strconfig','',this.strconfig).subscribe((ris)=>{
      this.reloadList.emit(true);
      this.modal.dismissAll();
    });
  }

  setIntegrationProperties(prop: any) {
    console.debug("newItem",prop);
    this.strconfig.customProperties = [];
    for(let k in prop) {
      this.strconfig.customProperties.push({
        key: prop[k].key,
        value: prop[k].val
      })
    }
  }
}
