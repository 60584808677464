<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default" *ngIf="gui.account">
          <div class="vsm-list">
            <div class="vsm-header">Main Navigation</div>
            <div class="vsm-item">
              <a routerLink="/application/structures" class="vsm-link">
                <i class="pe-7s-drawer icon-gradient bg-premium-dark vsm-icon"></i>
                <span class="vsm-title">Structures</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/application/integrations" class="vsm-link">
                <i class="pe-7s-plugin icon-gradient bg-premium-dark vsm-icon"></i>
                <span class="vsm-title">Integrations</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="gui.account.role=='ADMIN'">
              <a routerLink="/application/users" class="vsm-link">
                <i class="pe-7s-users icon-gradient bg-premium-dark vsm-icon"></i>
                <span class="vsm-title">Users</span>
              </a>
            </div>
            <div class="vsm-item" *ngIf="gui.account.role=='ADMIN'">
              <a routerLink="/application/trace" class="vsm-link">
                <i class="pe-7s-attention icon-gradient bg-premium-dark vsm-icon"></i>
                <span class="vsm-title">Trace</span>
              </a>
            </div>
            <div class="vsm-item">
              <a routerLink="/application/booking" class="vsm-link">
                <i class="pe-7s-note2 icon-gradient bg-premium-dark vsm-icon"></i>
                <span class="vsm-title">Booking</span>
              </a>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
