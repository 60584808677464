<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" class="btn-shadow me-3 btn btn-dark">
                <fa-icon [icon]="faStar"></fa-icon>
            </button>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew(content)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="d-md-flex justify-content-md-start p-4">
        <button class="btn" [ngClass]="list_view=='ACTIVE' ? 'btn-primary' : 'btn-link'" (click)="changeNav('ACTIVE')">ACTIVE</button>
        <button class="btn" [ngClass]="list_view=='REGISTERED' ? 'btn-primary' : 'btn-link'" (click)="changeNav('REGISTERED')">REGISTERED</button>
        <button class="btn" [ngClass]="list_view=='DELETED' ? 'btn-primary' : 'btn-link'" (click)="changeNav('DELETED')">DELETED</button>
    </div>
    <div class="card-header">
        <ngb-pagination [collectionSize]="totalItems" [(page)]="page"></ngb-pagination>
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
            <tr>
                <th class="text-center">#</th>
                <th>Name</th>
                <th class="text-center">Creation Date</th>
                <th class="text-center">Expire Date</th>
                <th class="text-center">Status</th>
                <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of users; index as i">
                <td class="text-center text-muted" [title]="item.id">#</td>
                <td>
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.username}}</div>
                                <div class="widget-subheading opacity-7"><small>{{item.profile.name}}</small></div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="text-center">{{item.dateCreated | date: 'dd/MM/YYYY HH:mm'}}</td>
                <td class="text-center">{{item.expireDate | date: 'dd/MM/YYYY HH:mm'}}</td>
                <td class="text-center">
                    <div class="table-icon" *ngIf="item.status=='ACTIVE'"><i class="fa fa-chain color-blu"></i></div>
                    <div class="table-icon" *ngIf="item.status!='ACTIVE'"><i class="fa fa-chain-broken color-red"></i></div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-sm mx-1" (click)="itemDetail(itemdetail,item)">
                        <i class="fa fa-wrench"></i>
                    </button>
                    <button type="button" class="btn btn-outline-success btn-sm mx-1" (click)="changeItemStatus(item,'ACTIVE')" *ngIf="list_view!='ACTIVE'">
                        <i class="fa fa-chain"></i>
                    </button>
                    <button type="button" class="btn btn-outline-danger btn-sm mx-1" (click)="changeItemStatus(item,'DELETED')" *ngIf="list_view=='ACTIVE'">
                        <i class="fa fa-chain-broken"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--div class="d-block text-center card-footer">
        <button class="me-2 btn-icon btn-icon-only btn btn-outline-danger">
            <i class="pe-7s-trash btn-icon-wrapper"> </i>
        </button>
        <button class="btn-wide btn btn-success">Save</button>
    </div-->
</div>

<ng-template #content let-modal>
    <app-user-new (reloadList)="reloadList()"></app-user-new>
</ng-template>

<ng-template #itemdetail>
    <app-user-detail [item]="selectedItem" (reloadList)="reloadList()"></app-user-detail>
</ng-template>



<div class="alert-wrapper" *ngIf="gui.auth_result!=null && gui.auth_result!=''">
    <ngb-alert [dismissible]="false" type="success" *ngIf="gui.auth_result=='200'">
        <strong>SUCCESS!</strong> Request completed.
    </ngb-alert>
    <ngb-alert [dismissible]="false" type="danger" *ngIf="gui.auth_result!='200'">
        <strong>ERROR!</strong> There are some problems.
    </ngb-alert>
</div>


