<div [ngClass]="{
'closed-sidebar' : globals.toggleSidebar,
'closed-sidebar-md' : globals.toggleSidebarMobile,
'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
'header-menu-open' : globals.toggleHeaderMobile,
'fixed-footer' : globals.toggleFixedFooter
}" [class]="'app-container app-theme-white'">
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <div class="app-main__outer">
    <div class="app-main__inner">
      <div [@architectUIAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </div>
    </div>
  </div>
  <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<ngx-loading-bar [fixed]="true" [color]="'var(--primary)'" *ngIf="!gui.loaded"></ngx-loading-bar>

<div class="loading-wrapper" *ngIf="!gui.loaded">
  <div class="loading-box">
    <ngb-progressbar class="mb-3" type="warning" [value]="100" [striped]="true" [animated]="true">
      <i>Loading ...</i>
    </ngb-progressbar>
  </div>
</div>
