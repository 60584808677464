<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" class="btn-shadow me-3 btn btn-dark">
                <fa-icon [icon]="faStar"></fa-icon>
            </button>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew(content)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header">
        <ngb-pagination [collectionSize]="totalItems" [(page)]="page"></ngb-pagination>
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
            <tr>
                <th class="text-center">#</th>
                <th>Name</th>
                <th class="text-center">PMS</th>
                <th class="text-center">Status</th>
                <th class="text-center">Actions</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of structures; index as i">
                <td class="text-center text-muted"><i [title]="item.id">#{{i}}</i></td>
                <td>
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left me-3">
                                <div class="widget-content-left">
                                    <img width="30" class="rounded-circle logo-system" src="./assets/images/avatars/{{item.pmsSystem}}.png" alt="">
                                </div>
                            </div>
                            <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.name}}</div>
                                <div class="widget-subheading opacity-7">{{item.checkinDate | date: 'dd/MM/YYYY HH:mm'}}</div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="text-center">{{item.pmsSystem}}</td>
                <td class="text-center">
                    <div class="table-icon" *ngIf="item.enabled==true"><i class="fa fa-chain color-blu"></i></div>
                    <div class="table-icon" *ngIf="item.enabled==false"><i class="fa fa-chain-broken color-red"></i></div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-sm mx-2" (click)="editItem(content, item)">
                        <i class="fa fa-wrench"></i>
                    </button>
                    <button type="button" class="btn btn-outline-dark btn-sm" (click)="preGenerateKey(contentkeygen)">
                        <i class="fa fa-key"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--div class="d-block text-center card-footer">
        <button class="me-2 btn-icon btn-icon-only btn btn-outline-danger">
            <i class="pe-7s-trash btn-icon-wrapper"> </i>
        </button>
        <button class="btn-wide btn btn-success">Save</button>
    </div-->
</div>

<ng-template #content let-modal>
    <app-integration-new *ngIf="!loadEdit" (reloadList)="reloadList()"></app-integration-new>
    <app-integration-edit [selectedItem]="selectedItem" (reloadList)="reloadList()" *ngIf="loadEdit"></app-integration-edit>
</ng-template>

<ng-template #contentkeygen let-modal>
    <div class="card generate-key-modal" *ngIf="generateKeyEnabled">
        <div class="card-header">
            <h4>GENERATE KEY</h4>
        </div>
        <div class="card-body row">
            <div class="col position-relative form-group form-style">
                <label>Username</label>
                <input class="form-control" [(ngModel)]="generateKeyAuth.username" type="text" />
            </div>
            <div class="col position-relative form-group form-style">
                <label>Password</label>
                <input class="form-control" [(ngModel)]="generateKeyAuth.password" type="password" />
            </div>
        </div>
        <div class="col-12 authkey-area" *ngIf="authkey && authkey!=null">
            <div class="authkey-area form-style">
                <label>BRiG KEY:</label>
                <small>{{authkey}}</small>
            </div>
        </div>
        <div class="card-footer d-md-flex justify-content-md-end">
            <button class="btn btn-link" (click)="modal.dismiss()">CLOSE</button>
            <button class="btn btn-primary" (click)="generateKey()">GENERATE</button>
        </div>
    </div>
</ng-template>


<div class="alert-wrapper" *ngIf="gui.auth_result!=null && gui.auth_result!=''">
    <ngb-alert [dismissible]="false" type="success" *ngIf="gui.auth_result=='200'">
        <strong>SUCCESS!</strong> Request completed.
    </ngb-alert>
    <ngb-alert [dismissible]="false" type="danger" *ngIf="gui.auth_result!='200'">
        <strong>ERROR!</strong> There are some problems.
    </ngb-alert>
</div>
