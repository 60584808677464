<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <!--div class="page-title-actions">
            <button type="button" class="btn-shadow me-3 btn btn-dark">
                <fa-icon [icon]="faStar"></fa-icon>
            </button>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew(content)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div-->
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="col-6 d-flex justify-content-end">
    </div>
    <div class="card-header">
        <ngb-pagination [collectionSize]="totalItems" [pageSize]="size" [(page)]="page"
                        [ellipses]="true" [boundaryLinks]="true" [maxSize]="5" (pageChange)="pageChange($event)"></ngb-pagination>
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
            <tr>
                <th class="text-center">Counter</th>
                <th>Name</th>
                <th class="text-center">Status</th>
                <th class="text-center">Last update</th>
                <th class="text-center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of logs; index as i">
                <td class="text-center text-muted">
                    <div class="badge badge-pill badge-info">{{item.counter}}</div>
                </td>
                <td>
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.structureName}}</div>
                                <div class="widget-subheading opacity-7">{{item.structureId}}</div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <div class="table-icon" *ngIf="item.status=='OK'"><i class="fa fa-circle color-green"></i></div>
                    <div class="table-icon" *ngIf="item.status!='OK'"><i class="fa fa-circle color-red"></i></div>
                </td>
                <td class="text-center">{{item.lastUpdate}}</td>
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-sm">
                        <i class="fa fa-info-circle"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--div class="d-block text-center card-footer">
        <button class="me-2 btn-icon btn-icon-only btn btn-outline-danger">
            <i class="pe-7s-trash btn-icon-wrapper"> </i>
        </button>
        <button class="btn-wide btn btn-success">Save</button>
    </div-->
</div>

<ng-template #content let-modal>
    <!--app-struction-detail></app-struction-detail-->
</ng-template>


