import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RestService} from "../../../../service/rest.service";
import {GuiService} from "../../../../service/gui.service";

@Component({
  selector: 'app-slope',
  templateUrl: './slope.component.html'
})
export class SlopeComponent implements OnInit {
  @Input('strconfig') strconfig: any;
  @Output() newItemEvent = new EventEmitter<string>();

  integrationProperties: any = [{
    key: "SLOPE_API_KEY",
    label: "API KEY",
    val: ""
  }];

  webhookProperties: any = {
    "structureId": null,
    "webHookProperties": {
      "endpoint": {
        "apiKey": "",
        "authHeaderType": "BEARER",
        "baseUrl": "https://pmsx-dot-revolution-plus-nuova-arch.oa.r.appspot.com/rev_push/v2/booking",
        "contentType": "application/json",
        "contentTypeInBody": false,
        "httpMethod": "POST",
        "method": "",
        "paginated": false,
        "pathParamNumber": 0,
        "returnObj": null
      },
      "name": ""
    }
  };
  webhookPropEdited: boolean = false;

  constructor(private rest: RestService, public gui: GuiService) { }

  ngOnInit(): void {
    console.debug("STR CFG",this.strconfig);
    this.initValue();
  }

  initValue() {
    for (let k in this.integrationProperties) {
      let item = this.integrationProperties[k];
      this.strconfig.customProperties[item.key] ? this.integrationProperties[k].val = this.strconfig.customProperties[item.key] : null;
    }
  }

  updateIntegrationProperty() {
    this.newItemEvent.emit(this.integrationProperties);
  }

  saveWebhook() {
    this.rest.postService('strconfig','',this.webhookProperties).subscribe((ris)=>{
      this.gui.auth_result = 200;
    });
  }

}
