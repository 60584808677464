import {Component, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../../../theme-options';
import {RestService} from '../../../../../service/rest.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  constructor(public globals: ThemeOptions, private rest: RestService) {
  }

  ngOnInit() {
  }

  logout() {
    this.rest.doLogout();
  }
}
