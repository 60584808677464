import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RestService} from "../../../service/rest.service";
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {GuiService} from "../../../service/gui.service";

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html'
})
export class UserNewComponent implements OnInit {
  @Output() reloadList = new EventEmitter();
  heading = 'Structure Detail';
  subheading = 'Area to view and configuration the structure integrations';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  faSave = faSave;
  faArrowLeft = faArrowLeft;
  langs: Array<any> = [{
    label: "English",
    value: "en"
  },{
    label: "Italiano",
    value: "it"
  }];

  user: any = {
    "authType": "BASIC",
    "clearPassword": "",
    "lang": "",
    "profile": null,
    "username": ""
  } ;
  profiles: Array<any> = [{
    "name": "ADMIN",
    "permissions": "a,b,c"
  },{
    "name": "CORPORATE_ADMIN",
    "permissions": "a,b,c"
  }];
  profileSelected: any = null;

  confirmPassword: any = "";

  constructor(private router: Router, public modal: NgbModal, private rest: RestService, public gui: GuiService) { }

  ngOnInit(): void {
  }

  save() {
    this.user.profile = this.profileSelected;

    if(this.confirmPassword==this.user.clearPassword) {
      this.rest.postService('user','',this.user).subscribe((ris)=>{
        this.gui.loaded = true;
        this.reloadList.emit();
        this.modal.dismissAll();
      });
    }
  }

}
