import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RestService} from '../../../service/rest.service';
import * as moment from 'moment/moment';
import {GuiService} from "../../../service/gui.service";

@Component({
  selector: 'app-integration-edit',
  templateUrl: './integration-edit.component.html'
})
export class IntegrationEditComponent implements OnInit {
  @Input('selectedItem') strconfig;
  @Output() reloadList = new EventEmitter();

  pmslist: any = [{
    id: 0,
    name: "CINQUESTELLE",
    label: "Hotel 5 Stelle"
  },{
    id: 1,
    name: "SLOPE",
    label: "Slope"
  },{
    id: 2,
    name: "ZAK",
    label: "Zak"
  },{
    id: 3,
    name: "CLOUDBEDS",
    label: "CloudBeds"
  }];
  selectedPMS: any = null;
  customProperties: Array<any> = [];

  timeSlot: Array<string> = [
    "00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00",
    "12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00",
  ];
  selectedSlot: any = null;
  checkinDate: any = null;

  structures: any = [];
  selectedStr: any = null;

  constructor(private router: Router, public modal: NgbModal, private rest: RestService, public gui: GuiService) { }

  ngOnInit(): void {
    console.debug("strconfig",this.strconfig);
    this.selectedPMS = this.strconfig.pmsSystem;
    this.selectedSlot = this.strconfig.timeSlot && this.strconfig.timeSlot!=null ? this.strconfig.timeSlot.split("::") : null;
    this.checkinDate = moment(this.strconfig.checkinDate,'x').format('YYYY-MM-DD');
    this.getStructures();
  }

  getStructures() {
    this.rest.getService('structure','?page=0&size=300').subscribe((ris)=> {
      this.structures = ris.items;
      for(let k in this.structures) {
        this.structures[k].id == this.strconfig.structureId ? this.selectedStr = this.structures[k] : null;
      }
    });
  }
  changePMSSelect() {
    console.debug("PMS",this.selectedPMS);
  }
  goBack() {
    this.router.navigate(['structures']);
  }

  save() {

    let param = {
      "checkinDate": this.checkinDate ? moment(this.checkinDate).format('DD/MM/YYYY') : null,
      "customProperties": [],
      "name": this.strconfig.name,
      "pmsSystem": this.selectedPMS,
      "structureId": this.selectedStr.id,
      "timeSlot": this.selectedSlot && this.selectedSlot.length > 0 ? this.selectedSlot.join("::") : null
    }

    console.debug("-------->",this.strconfig.customProperties);
    for(let k in this.strconfig.customProperties) {
      console.debug("Custom properties", this.strconfig.customProperties);
      param.customProperties.push({
        key: this.strconfig.customProperties[k].key,
        value: this.strconfig.customProperties[k].value
      })
    }
    console.debug("PRE SAVE",this.strconfig,param);

    this.rest.postService('strconfig','',param).subscribe((ris)=>{
      this.gui.auth_result = 200;
      this.reloadList.emit(true);
      this.modal.dismissAll();
    });
  }

  setIntegrationProperties(prop: any) {
    this.strconfig.customProperties = [];
    for(let k in prop) {
      console.debug("newItem",k,prop);
      this.strconfig.customProperties.push({
        key: prop[k].key,
        value: prop[k].val
      })
    }

    console.debug("this.strconfig",this.strconfig);
  }
}
