<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" class="btn-shadow me-3 btn btn-dark">
                <fa-icon [icon]="faStar"></fa-icon>
            </button>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew(structurenew)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header">
        <ngb-pagination [pageSize]="size" [collectionSize]="totalItems" [(page)]="page" (pageChange)="changePage($event)"></ngb-pagination>
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
            <thead>
            <tr>
                <th class="text-center">#</th>
                <th>Name</th>
                <th class="text-center">City</th>
                <th class="text-center">Status</th>
                <th class="text-center"></th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of structures; index as i">
                <td class="text-center text-muted">{{i+1}}</td>
                <td>
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.name}} - <small>#{{item.id}}</small></div>
                                <div class="widget-subheading opacity-7">{{item.dateCreated | date: 'dd/MM/YYYY HH:mm'}}</div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="text-center">{{item.city}}</td>
                <td class="text-center">
                    <div class="table-icon" *ngIf="item.enabled==true"><i class="fa fa-chain color-blu"></i></div>
                    <div class="table-icon" *ngIf="item.enabled==false"><i class="fa fa-chain-broken color-red"></i></div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-sm" (click)="itemDetail(structuredetail,item)">
                        <i class="fa fa-wrench"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--div class="d-block text-center card-footer">
        <button class="me-2 btn-icon btn-icon-only btn btn-outline-danger">
            <i class="pe-7s-trash btn-icon-wrapper"> </i>
        </button>
        <button class="btn-wide btn btn-success">Save</button>
    </div-->
</div>

<ng-template #structurenew>
    <app-structure-new (reloadList)="reloadList()"></app-structure-new>
</ng-template>

<ng-template #structuredetail>
    <app-structure-detail (reloadList)="reloadList()" [item]="selectedItem"></app-structure-detail>
</ng-template>



<div class="alert-wrapper" *ngIf="gui.auth_result!=null && gui.auth_result!=''">
    <ngb-alert [dismissible]="false" type="success" *ngIf="gui.auth_result=='200'">
        <strong>SUCCESS!</strong> Request completed.
    </ngb-alert>
    <ngb-alert [dismissible]="false" type="danger" *ngIf="gui.auth_result!='200'">
        <strong>ERROR!</strong> There are some problems.
    </ngb-alert>
</div>


