import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';

// DEMO PAGES

// Dashboards
import {AnalyticsComponent} from './pages/Dashboards/analytics/analytics.component';

// Auth
import {ForgotPasswordBoxedComponent} from './pages/Auth/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './pages/Auth/login-boxed/login-boxed.component';
import {RegisterBoxedComponent} from './pages/Auth/register-boxed/register-boxed.component';

// Structures
import {StructureListComponent} from './pages/Structures/structure-list/structure-list.component';
import {UsersListComponent} from './pages/Users/user-list/users-list.component';
import {IntegrationListComponent} from './pages/Integrations/integration-list/integration-list.component';
import {StructureNewComponent} from './pages/Structures/structure-new/structure-new.component';
import {TraceComponent} from "./pages/Monitoring/trace/trace.component";
import {BookingListComponent} from "./pages/Booking/booking-list/booking-list.component";


const routes: Routes = [
  {
    path: 'application',
    component: BaseLayoutComponent,
    children: [

      // Dashboads
      {path: 'dashboard', component: AnalyticsComponent, data: {extraParameter: 'elementsMenu'} },

      // Structures
      {path: 'structures', component: StructureListComponent, data: {extraParameter: 'elementsMenu'} },
      {path: 'structures-new', component: StructureNewComponent, data: {extraParameter: 'elementsMenu'} },
      {path: 'structures-detail', component: StructureNewComponent, data: {extraParameter: 'elementsMenu'} },

      // Integrations
      {path: 'integrations', component: IntegrationListComponent, data: {extraParameter: 'elementsMenu'} },

      // Users
      {path: 'users', component: UsersListComponent, data: {extraParameter: 'tablesMenu'} },

      // Trace
      {path: 'trace', component: TraceComponent, data: {extraParameter: 'tablesMenu'} },

      // Booking
      {path: 'booking', component: BookingListComponent, data: {extraParameter: 'tablesMenu'} },

    ]

  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages

      {path: '', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'login', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'register-boxed', component: RegisterBoxedComponent, data: {extraParameter: ''}},
      {path: 'forgot-password', component: ForgotPasswordBoxedComponent, data: {extraParameter: ''}},
    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
