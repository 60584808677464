<div class="main-card-with-tab mb-3 card">
    <div class="card-header">
        <h2>Booking detail: #{{data.id}}</h2>
    </div>
    <div class="card-body">
        <div class="container" id="container-id"></div>
    </div>
    <div class="card-footer text-end">
        <button type="button" class="btn btn-light mx-3" (click)="modal.dismissAll('Close click')">Close</button>
    </div>
</div>


