import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RestService} from '../../../service/rest.service';
import {GuiService} from "../../../service/gui.service";

@Component({
    selector: 'app-login-boxed',
    templateUrl: './login-boxed.component.html',
    styles: []
})
export class LoginBoxedComponent implements OnInit {
    actionloading: boolean = false;
    auth_result: string = "";
    authparam: any = {
        "username": "",
        "password": ""
    };

    constructor(private route: Router, private rest: RestService, private gui: GuiService) {
    }

    ngOnInit() {
    }

    doLogin() {
        this.actionloading = true;
        this.rest.doLogin('login', this.authparam).subscribe((ris) => {
                this.rest.setTkn(ris['token']);

                let tmp = this.gui.parseJwt(ris['token']);
                let full_role = tmp.roles.split("::");
                tmp.role = full_role[0];
                tmp.roleID = full_role[1];
                this.gui.account = tmp;
                console.debug("ACCOUNT",this.gui.account);

                this.route.navigate(['application/structures']);
            },
            (err) => {
                this.actionloading = false;
                this.auth_result = ''+err.status;

                setTimeout(() => this.auth_result="", 6000);
            });
    }
}
