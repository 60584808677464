<div class="row my-4">
    <div class="col-6" *ngFor="let item of integrationProperties">
        <div class="position-relative form-group">
            <label for="stremail">{{item.label}}</label>
            <input class="form-control" id="stremail" name="stremail" type="email" [(ngModel)]="item.val" (change)="updateIntegrationProperty()">
        </div>
    </div>
</div>

<!-- TAB SOURCE, STATUS, WEBOOK -->
<div class="row mt-5" *ngIf="strconfig.id">
    <div class="col-12" id="integration-tab">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">

            <!-- SOURCE -->
            <li ngbNavItem>
                <a ngbNavLink>Source mapping
                    <button class="btn btn-success btn-sm" *ngIf="JSON.stringify(sources) != JSON.stringify(sourceOrigin)" (click)="saveSource()">
                        <i class="fa fa-save"></i>
                    </button>
                </a>
                <ng-template ngbNavContent>
                    <div class="mt-5">
                        <div class="input-group" role="group" *ngFor="let item of sources, let i = index">
                            <div class="input-group-append">
                                <label class="p-3"> {{item.key}} </label>
                            </div>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="item.val" (change)="changeSource()">
                        </div>
                    </div>
                </ng-template>
            </li>

            <!-- SALES CHANNEL -->
            <li ngbNavItem>
                <a ngbNavLink>Sales channel mapping
                    <button class="btn btn-success btn-sm" *ngIf="JSON.stringify(saleschannels) != JSON.stringify(saleschannelOrigin)" (click)="saveSC()">
                        <i class="fa fa-save"></i>
                    </button>
                </a>
                <ng-template ngbNavContent>
                    <div class="mt-5">
                        <div class="input-group" role="group" *ngFor="let item of saleschannels, let i = index">
                            <div class="input-group-append">
                                <label class="p-3"> {{item.key}} </label>
                            </div>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="item.val" (change)="changeSC()">
                        </div>
                    </div>
                </ng-template>
            </li>

            <!-- STATUS -->
            <li ngbNavItem>
                <a ngbNavLink>Status mapping
                    <button class="btn btn-success btn-sm" *ngIf="statusedited" (click)="saveStatus()">
                        <i class="fa fa-save"></i>
                    </button>
                </a>
                <ng-template ngbNavContent>
                    <div class="mt-5">
                        <div class="input-group" role="group" *ngFor="let item of statusM, let i = index">
                            <div class="input-group-append">
                                <label class="p-3"> {{item.key}} </label>
                            </div>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="item.val" (change)="changeStatus()">
                        </div>

                    </div>
                </ng-template>
            </li>

            <!-- WEBHOOK -->
            <li ngbNavItem>
                <a ngbNavLink>Webhook Properties
                    <button class="btn btn-success btn-sm" *ngIf="webhookPropEdited" (click)="saveWebhook()">
                        <i class="fa fa-save"></i>
                    </button>
                </a>
                <ng-template ngbNavContent>
                    <div class="row mt-5">
                        <div class="col-4">
                            <label>Name</label>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="webhookProperties.webHookProperties.name" (change)="webhookPropEdited=true">
                        </div>
                        <div class="col-8">
                            <label>URL</label>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="webhookProperties.webHookProperties.endpoint.baseUrl" (change)="webhookPropEdited=true">
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
