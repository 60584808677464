<div class="main-card-with-tab mb-3 card">
    <div class="card-header">
        <h2>Create structure</h2>
    </div>
    <div class="card-body">
        <div class="container">
            <div class="row mt-4">
                <div class="col-12">
                    <div class="position-relative form-group">
                        <label for="strname">Structure name</label>
                        <input class="form-control" id="strname" name="strname" type="text" [(ngModel)]="structure.name">
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-5">
                    <div class="position-relative form-group">
                        <label for="straddress">Address</label>
                        <input class="form-control" id="straddress" name="straddress" type="text" [(ngModel)]="structure.address">
                    </div>
                </div>
                <div class="col-2">
                    <div class="position-relative form-group">
                        <label for="strzip">CAP/ZIP Code</label>
                        <input class="form-control" id="strzip" name="strzip" type="text" [(ngModel)]="structure.cap">
                    </div>
                </div>
                <div class="col-5">
                    <div class="position-relative form-group">
                        <label for="strcity">City</label>
                        <input class="form-control" id="strcity" name="strcity" type="text" [(ngModel)]="structure.city">
                    </div>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="stremail">Email</label>
                        <input class="form-control" id="stremail" name="stremail" type="email" [(ngModel)]="structure.email">
                    </div>
                </div>
                <div class="col-6">
                    <div class="position-relative form-group">
                        <label for="strphone">Phone</label>
                        <input class="form-control" id="strphone" name="strphone" type="tel" [(ngModel)]="structure.phoneNumber">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button type="button" class="btn btn-light mx-3" (click)="modal.dismissAll('Close click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="save()">SAVE</button>
    </div>
</div>


