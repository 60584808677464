import { Injectable } from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class GuiService {
  componentView: any = null;
  auth_result: any = null;
  account: any = null;
  loaded: boolean = true; // Indica se la pagina o contenuto principale è stato caricato

  constructor(private router: Router) { }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  reloadAccountByToken() {
    let tmp = sessionStorage.getItem('tkn');

    if(tmp && tmp!=null) {
      this.account = this.parseJwt(tmp);
      let tmp_role = this.account.roles.split("::");
      this.account.role = tmp_role[0];
      this.account.roleID = tmp_role[1];

    } else {
      this.router.navigate(['login']);
    }



  }

}
