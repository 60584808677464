import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RestService} from "../../../../service/rest.service";

@Component({
  selector: 'app-cloudbeds',
  templateUrl: './cloudbeds.component.html',
  styleUrls: ['./cloudbeds.component.sass']
})
export class CloudbedsComponent implements OnInit {
  @Input('strconfig') strconfig: any;
  @Output() newItemEvent = new EventEmitter<string>();
  JSON: any = JSON;

  integrationProperties: any = [{
    key: "API_KEY",
    label: "API KEY",
    val: ""
  },{
    key: "HOTEL_CODE",
    label: "HOTEL CODE",
    val: ""
  }];

  webhookProperties: any = {
    "structureId": null,
    "webHookProperties": {
      "endpoint": {
        "apiKey": "",
        "authHeaderType": "BEARER",
        "baseUrl": "https://pmsx-dot-revolution-plus-nuova-arch.oa.r.appspot.com/rev_push/v2/booking",
        "contentType": "application/json",
        "contentTypeInBody": false,
        "httpMethod": "POST",
        "method": "",
        "paginated": false,
        "pathParamNumber": 0,
        "returnObj": null
      },
      "name": ""
    }
  };
  webhookPropEdited: boolean = false;

  constructor(private rest: RestService) { }

  ngOnInit(): void {
    this.initValue();
  }

  initValue() {
    for (let k in this.integrationProperties) {
      let item = this.integrationProperties[k];
      this.strconfig.customProperties[item.key] ? this.integrationProperties[k].val = this.strconfig.customProperties[item.key] : null;
    }
  }

  updateIntegrationProperty() {
    this.newItemEvent.emit(this.integrationProperties);
  }

  saveStatus() {}

  saveWebhook() {}
}
