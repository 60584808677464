<div class="row my-4">
    <div class="col-6" *ngFor="let item of integrationProperties">
        <div class="position-relative form-group">
            <label for="stremail">{{item.label}}</label>
            <input class="form-control" id="stremail" name="stremail" type="text" [(ngModel)]="item.val" (change)="updateIntegrationProperty()">
        </div>
    </div>
</div>

<!-- TAB SOURCE, STATUS, WEBOOK -->
<div class="row mt-5" *ngIf="strconfig.id">
    <div class="col-12" id="integration-tab">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">

            <!-- WEBHOOK -->
            <li ngbNavItem>
                <a ngbNavLink>Webhook Properties
                    <button class="btn btn-success btn-sm" *ngIf="webhookPropEdited" (click)="saveWebhook()">
                        <i class="fa fa-save"></i>
                    </button>
                </a>
                <ng-template ngbNavContent>
                    <div class="row mt-5">
                        <div class="col-4">
                            <label>Name</label>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="webhookProperties.webHookProperties.name" (change)="webhookPropEdited=true">
                        </div>
                        <div class="col-8">
                            <label>URL</label>
                            <input class="form-control input-sm mb-2" type="text" [(ngModel)]="webhookProperties.webHookProperties.endpoint.baseUrl" (change)="webhookPropEdited=true">
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
