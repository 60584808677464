/* tslint:disable */
import {Component, OnInit} from '@angular/core';
import {faStar, faPlus} from '@fortawesome/free-solid-svg-icons';
import {RestService} from '../../../service/rest.service';
import {GuiService} from '../../../service/gui.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-user-list',
    templateUrl: './structure-list.component.html'
})
export class StructureListComponent implements OnInit {
    heading = 'Structures';
    subheading = 'List of structure integrations';
    icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

    faStar = faStar;
    faPlus = faPlus;

    totalItems: number = 0;
    page: number = 0;
    size: number = 25;

    structures: any = [];
    selectedItem: any = null;

    constructor(private router: Router, private rest: RestService, public gui: GuiService, private modalService: NgbModal) {
    }

    ngOnInit() {
        this.gui.componentView = 'structures';

        this.structures = [];
        this.loadItems();
    }

    loadItems() {
        this.gui.loaded = false;
        let pag = this.page > 0 ? this.page - 1 : 0;
        this.rest.getService('structure', '?page=' + pag + '&size=' + this.size).subscribe((ris) => {
                console.debug("RIS", ris);
                this.structures = ris.items;
                this.totalItems = ris.totalItems;
            },
            (err) => {
                // nothing
            },
            () => {
                this.gui.loaded = true;
            });
    }

    createNew(content: any) {
        this.modalService.open(content, {size: 'xl'});
    }

    itemDetail(content, item: any) {
        this.selectedItem = item;
        this.modalService.open(content, {size: 'xl'});
    }

    reloadList() {
        this.loadItems();
    }

    changePage(ev: any) {
        this.page = ev;
        this.loadItems();
    }
}
