import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {RestService} from '../../../service/rest.service';
import {GuiService} from "../../../service/gui.service";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent implements OnInit {
  @Input() item:any;
  @Output() reloadList = new EventEmitter();
  heading = 'Structure Detail';
  subheading = 'Area to view and configuration the structure integrations';
  icon = 'pe-7s-drawer icon-gradient bg-happy-itmeo';

  faSave = faSave;
  faArrowLeft = faArrowLeft;
  langs: Array<any> = [{
    label: "English",
    value: "en_EN"
  }]

  user: any = {
    "authType": "",
    "clearPassword": "",
    "lang": "",
    "profile": {},
    "username": ""
  } ;
  profiles: Array<any> = [{
    "name": "ADMIN",
    "permissions": "a,b,c"
  },{
    "name": "CORPORATE_ADMIN",
    "permissions": "a,b,c"
  }];
  profileSelected: any = null;

  confirmPassword: any = "";

  constructor(private router: Router, public modal: NgbModal, private rest: RestService, public gui: GuiService) { }

  ngOnInit(): void {
    console.debug("ITEM",this.item);
    delete this.item.password;
    this.item.clearPassword = "";

    for(let k in this.profiles) {
      this.profiles[k].name == this.item.profile.name ? this.profileSelected = this.profiles[k] : null;
    }

    this.user = this.item;
  }

  save() {
    this.gui.loaded = false;
    let param = {
      "authType": this.user.authType,
      "clearPassword": this.user.clearPassword,
      "id": this.user.id,
      "lang": this.user.lang,
      "profile": this.profileSelected,
      "username": this.user.username
    };


    if(this.confirmPassword==this.user.clearPassword) {
      this.rest.putService('user','',param).subscribe((ris)=>{
        this.gui.loaded = true;
        this.reloadList.emit();
        this.modal.dismissAll();
      });
    }
  }

}
