/* tslint:disable */
import {Component, OnInit} from '@angular/core';
import {RestService} from '../../../service/rest.service';
import {faPlus, faStar} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {GuiService} from '../../../service/gui.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-list',
  templateUrl: './users-list.component.html'
})
export class UsersListComponent implements OnInit {
  heading = 'Users';
  subheading = 'List of users registered';
  icon = 'pe-7s-users icon-gradient bg-happy-itmeo';
  list_view: string = "ACTIVE";

  totalItems: number = 0;
  page: number = 1;
  size: number = 25;

  users: any = [];
  selectedItem: any = null;

  faStar = faStar;
  faPlus = faPlus;

  constructor(private router: Router, private rest: RestService, public gui: GuiService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.users = [];
    this.loadItems();
  }

  loadItems() {
    this.gui.loaded = false;
    this.rest.getService('user','?page='+(this.page-1)+'&size='+this.size+'&filter=status:eq:'+this.list_view, ).subscribe((ris)=> {
      console.debug("RIS",ris);
      this.users = ris.items;
      this.totalItems = ris.totalItems;

      this.gui.loaded = true;

    });
  }

  createNew(content: any) {
    this.modalService.open(content, { size: 'xl' });
  }

  itemDetail(content, item: any) {
    this.selectedItem = item;
    this.modalService.open(content, {size: 'xl'});
  }

  reloadList() {
    this.loadItems();
  }

  changeNav(tab:string) {
    this.list_view = tab;
    this.loadItems();
  }

  changeItemStatus(item: any, act: string) {
    this.gui.loaded = false;
    console.debug("ITEM",item);
    let param = {
      "status": act
    };

    this.rest.patchService("user",'/'+item.id+'/status',param).subscribe((ris)=>{
      this.loadItems();
      this.gui.loaded = true;
    })
  }
}
