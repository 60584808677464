<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow me-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button>
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew()">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Create New
      </button>
    </div>
  </div>
</div>
