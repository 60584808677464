<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <!--div class="page-title-actions">
            <button type="button" class="btn-shadow me-3 btn btn-dark">
                <fa-icon [icon]="faStar"></fa-icon>
            </button>
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="createNew(content)">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div-->
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <ng-select [items]="structures"
                           bindLabel="name"
                           bindValue="id"
                           [(ngModel)]="filteredStructure"
                           (search)="searchStructure()"
                            (change)="loadItems()"
                            class="custom-select-group">
                </ng-select>
                <button class="btn btn-outline-primary" (click)="loadItems()">Refresh</button>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <ngb-pagination [collectionSize]="totalItems" [pageSize]="size" [(page)]="page"
                                [ellipses]="true" [boundaryLinks]="true" [maxSize]="5" (pageChange)="pageChange($event)"></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="align-middle mb-0 table table-borderless table-striped table-hover table-sm">
            <thead>
            <tr>
                <th class="text-center">#</th>
                <th>Period</th>
                <th class="text-center">Room Code</th>
                <th class="text-center">Amount</th>
                <th class="text-center">Status</th>
                <th class="text-center"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of bookings; index as i">
                <td class="text-center text-muted">
                    <div class="badge badge-pill badge-info" title="{{item.id}}">#</div>
                </td>
                <td>
                    <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left flex2">
                                <div class="widget-heading">{{item.checkin | date:"dd/MM/YYYY"}} -> {{item.checkout | date:"dd/MM/YYYY"}}</div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="text-center">{{item.roomCode}} [{{item.roomCodeAssigned}}]</td>
                <td class="text-center">{{item.amount | number : '1.2-2'}} {{item.currency}}</td>
                <td class="text-center">
                    <div class="table-icon" *ngIf="item.status=='CONFIRMED'"><i class="fa fa-circle color-green"></i></div>
                    <div class="table-icon" *ngIf="item.status=='DAY_USE'"><i class="fa fa-circle color-green"></i></div>
                    <div class="table-icon" *ngIf="item.status=='DELETED'"><i class="fa fa-circle color-red"></i></div>
                    <div class="table-icon" *ngIf="item.status=='NO_SHOW'"><i class="fa fa-circle color-yellow"></i></div>
                    <div class="table-icon" *ngIf="item.status=='OPTION'"><i class="fa fa-circle color-info"></i></div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-primary btn-sm" (click)="viewItem(content,item)">
                        <i class="fa fa-info-circle"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--div class="d-block text-center card-footer">
        <button class="me-2 btn-icon btn-icon-only btn btn-outline-danger">
            <i class="pe-7s-trash btn-icon-wrapper"> </i>
        </button>
        <button class="btn-wide btn btn-success">Save</button>
    </div-->
</div>

<ng-template #content let-modal>
    <app-booking-detail [data]="selectedItem.json"></app-booking-detail>
</ng-template>


